import { defineStore } from 'pinia';
import { addInternalNotification, isLoggedIn } from '../../assets/js/utils';
import { Notice } from "../../assets/js/Notice";

export const useUserStore = defineStore('User', {
  state: () => ({
    followedUsersIds: [],
		subscribedUsersIds: [],
    blockedUserIds: [],
    blockingUserIds: [],
    likedCommentIds: new Set(),
  }),
  getters: {
    userCanSettle: () => {
      return window.WP.user.role === 'Admin' || window.WP.user.role === 'Moderator' || window.WP.user.role === 'Redaktor';
    },
    isElevatedUser: () => {
      return window.WP.user.role === 'Admin' || window.WP.user.role === 'Moderator' || window.WP.user.role === 'Redaktor' || window.WP.user.role === 'Autor';
    },
  },
  actions: {
    getFollowedUsersIds() {
      if (!isLoggedIn()) {
        return;
      }
      axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_get_followed_users_ids',
        })
        .then(response => {
          if (response.data.success) {
            this.followedUsersIds = response.data.data;
          }
        });
    },
    async followUser(userId, isCancel = false) {
      if (this.blockedUserIds.includes(userId)) {
        return;
      }
      return axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_add_follow',
          followed_user_id: userId
        })
        .then(response => {
          if (response.data.success) {
            this.followedUsersIds.push(Number(userId));
            if (!isCancel) {
              addInternalNotification(new Notice()
                  .setContent(response.data.data.message)
                  .setCancelAction(this.unfollowUser.bind(this, userId, true)));
            }
	          const data =  {
							id: response.data.data.id,
		          recipientId: +userId,
		          comment_id: null,
		          post_id: null,
		          sender_id: window.WP.user.id,
		          seen: '0',
		          created_at: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'),
		          type: 'Follow',
		          sender_avatar: window.WP.user.avatar,
		          sender_name: window.WP.user.name
	          };
	          socket?.emit('notify-user', data);
          } else {
            addInternalNotification(new Notice().setContent(response.data.data));
          }
        });
    },
    async unfollowUser(userId, isCancel = false) {
      return axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_delete_follow',
          follow_id: userId
        })
        .then(response => {
          if (response.data.success) {
            let index = this.followedUsersIds.findIndex(id => id === Number(userId));
            this.followedUsersIds.splice(index, 1);
            if (!isCancel) {
              addInternalNotification(new Notice()
                  .setContent(response.data.data.message)
                  .setCancelAction(this.followUser.bind(this, userId, true)));
            }
          } else {
            addInternalNotification(new Notice().setContent(response.data.data));
          }
        });
    },
    isUserFollowed(userId) {
      return this.followedUsersIds.includes(Number(userId));
    },
		async subscribeUser(userId, isCancel = false){
			return axios
				.post(window.WP.ajaxUrl, {
					endpoint: 'api_add_subscription',
					subscribed_user_id: userId
				})
				.then(response => {
					if (response.data.success) {
						this.subscribedUsersIds.push(Number(userId));
						if (!isCancel) {
							addInternalNotification(new Notice()
								.setContent(response.data.data.message)
								.setCancelAction(this.unfollowUser.bind(this, userId, true)));
						}
					} else {
						addInternalNotification(new Notice().setContent(response.data.data));
					}
				})
		},
		async unsubscribeUser(userId, isCancel = false) {
			return axios
				.post(window.WP.ajaxUrl, {
					endpoint: 'api_delete_subscription',
					user_id: userId
				})
				.then(response => {
					if (response.data.success) {
						let index = this.subscribedUsersIds.findIndex(id => id === Number(userId));
						this.subscribedUsersIds.splice(index, 1);
						if (!isCancel) {
							addInternalNotification(new Notice()
								.setContent(response.data.data.message)
								.setCancelAction(this.followUser.bind(this, userId, true)));
						}
					} else {
						addInternalNotification(new Notice().setContent(response.data.data));
					}
				})
		},
		isUserSubscribed(userId) {
			return this.subscribedUsersIds.includes(Number(userId));
		},
		getSubscribedUsersIds() {
			if (!isLoggedIn()) {
				return;
			}
			axios
				.post(window.WP.ajaxUrl, {
					endpoint: 'api_get_user_subscriptions',
				})
				.then(response => {
					if (response.data.success) {
						this.subscribedUsersIds = response.data.data.map(v => +v);
					}
				});
		},



    // async saveUserSettings() {
    //   return axios
    //     .post(window.WP.ajaxUrl, {
    //       endpoint: 'api_save_user_settings',
    //       user_id: window.WP.user.id,
    //       text_notifications: this.textNotifications,
    //       push_notifications: this.pushNotifications,
    //       sound_notifications: this.soundNotifications,
    //       sound_notifications_type: this.soundNotificationsType,
    //       sound_name: this.notificationSound,
    //       volume: this.notificationVolume,
    //       form_type: this.typersFormType,
    //     });
    // },
    getBlockedUsersIds() {
      if (!isLoggedIn()) {
        return;
      }
      axios
          .post(window.WP.ajaxUrl, {
            endpoint: 'api_get_blocked_users_ids'
          })
          .then(response => {
            if (response.data.success) {
              this.blockedUserIds = response.data.data;
            }
          });
    },
    async blockUser(userId, isCancel = false) {
      return axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_block_user',
          blocked_user_id: userId
        })
        .then(response => {
          if (response.data.success) {
            this.blockedUserIds.push(Number(userId));
            if (!isCancel) {
              addInternalNotification(new Notice().setContent(response.data.data.message)
                  .setCancelAction(this.unblockUser.bind(this, userId, true)));
            }
          } else {
            addInternalNotification(new Notice().setContent(response.data.data));
          }
        });
    },
    async unblockUser(userId, isCancel = false) {
      return axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_unblock_user',
          blocked_user_id: userId
        })
        .then(response => {
          if (response.data.success) {
            let index = this.blockedUserIds.findIndex(id => id === Number(userId));
            this.blockedUserIds.splice(index, 1);
            if (!isCancel) {
              addInternalNotification(new Notice().setContent(response.data.data.message)
                  .setCancelAction(this.blockUser.bind(this, userId, true)));
            }
          } else {
            addInternalNotification(new Notice().setContent(response.data.data));
          }
        });
    },
		isUserBlocked(userId) {
			return this.blockedUserIds.includes(Number(userId));
		},
    
    getBlockingUsersIds() {
      axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_get_blocking_users_ids',
        })
        .then(response => {
          this.blockingUserIds = response.data.data;
        });
    },
		isUserBlocking(userId) {
			return this.blockingUserIds.includes(Number(userId));
		},

    getLikedCommentIds() {
      if (this.likedCommentIds.size > 0) {
        return this.likedCommentIds;
      }

      axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_get_liked_comment_ids',
        })
        .then(response => {
          this.likedCommentIds = new Set(response.data.data);
        });

      return this.likedCommentIds;
    },
    addLikedCommentId(commentId) {
      this.likedCommentIds.add(+commentId);
    },
    removeLikedCommentId(commentId) {
      this.likedCommentIds.delete(+commentId);
    },
    isCommentLiked(commentId) {
      return this.likedCommentIds.has(+commentId);
    },
  }
});